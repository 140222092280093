/* Misc UI stuff. */

.Arrow {
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 0;
}

.Arrow.left {
  transform: rotate(180deg);
}

.Arrow.down {
  transform: rotate(90deg);
}

.Arrow.up {
  transform: rotate(270deg);
}

.Centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Centered p {
  margin-left: 10px;
}

.PoweredBy p {
  margin: 0;
}

.PoweredBy img {
  width: 400px;
}

.Spinner {
  width: 40px;
  height: 40px;
}

.buttonSpinner {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}

.Spinner::after, .buttonSpinner::after {
  content: '';
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 5px solid rgba(0, 0, 0, 0.25);
  border-left: 5px solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.SpinnerOverlay {
  position: relative;
}

.overlayShadow {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 30px 50px rgba(0, 0, 0, 0.25);
  position: absolute;
  inset: 50px;
}

.overlayCenter {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.PageLoader {
  /* Hack. Intention is really to center the spinner on the page but we can't
  easily get a container of suitable size. Use 50vh not not overflow the page
  all the time */
  height: 50vh;
  width: 100%;
}

.danger {
  color: var(--vt-danger);
}

.status-good
{ background-color: var(--bevi-green); }
.status-warning
{ background-color: var(--vt-accent1); }
.status-danger
{ background-color: var(--vt-danger); }

button.surfaceLevel {
  background-image: url('../../icons/water_drop.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
}

button.add, button.remove, button.copy, button.regular, button.arrow, button.surfaceLevel {
  background-color: var(--bevi-65-black);
  box-shadow: var(--basic-shadow);
  border-radius: 10px;
}

button.add, button.remove, button.copy, button.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bevi-white);
}

button.add, button.remove, button.copy, button.surfaceLevel {
  width: 50px;
  height: 50px;
}

button.add {
  font-size: 50px;
}

button.remove {
  font-size: 35px;
  font-weight: bold;
}

button.copy img {
  width: 25px;
}

button.regular {
  padding: 0px 25px;
  height: 60px;
}

button:disabled.regular {
  cursor: default;
  background-color: var(--vt-more-light-gray);
  pointer-events: none;
}

button.arrow {
  width: 60px;
  height: 60px;
}

button.add:hover, button.arrow:hover, button.regular.status-good:hover {
  background-color: var(--bevi-green);
}

button.regular.status-warning:hover {
  background-color: var(--vt-accent1);
}

button.regular.status-danger:hover {
  background-color: var(--vt-danger);
}

button.remove:hover {
  background-color: var(--vt-danger);
}

button.copy:hover, button.surfaceLevel:hover {
  background-color: var(--bevi-blue);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 450px) {
  .PoweredBy img {
    width: 300px;
  }
}