@import url('./theming/colors.css');

.app {
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 13pt;
  color: #1D1D1B;
}

/* App layout */
.baseUI {
  display: flex; /* desktop */
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  background-color: var(--bevi-white);
}

.mainPanelWrapper {
  flex-grow: 1;
  position: relative;
}

.mainPanel {
  position: absolute;
  inset: 0;

  overflow-y: auto;
  padding: 20px 30px 20px 0;
  padding-left: 0;
  padding-bottom: 100px;
  background: local no-repeat bottom 13px right 30px/200px 85px url('./icons/BEVI.svg');
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

button {
  border: none;
  padding: 0;
  cursor: pointer;
}

@media all and (max-width: 992px) {
  .mainPanel {
    padding: 20px 10px 180px 10px;
    background-position: bottom 90px right 30px;
  }
}