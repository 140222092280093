:root {
  --vt-fg: white;
  --vt-bg: #6a6a69;

  --vt-gray: #575756;
  --vt-green: #93C152;

  /* secondary colors */
  --vt-accent1: #EFB600;
  --vt-accent2: #0093E5;
  --vt-accent3: #0033FF;

  --vt-danger: #E52000;
  --vt-warning: #EFB600;

  /* undefined colors? */
  --vt-light-gray: #878787; 
  --vt-more-light-gray: #a5a5a4;


  /* BEVI */
  --bevi-green: #009640;
  --bevi-white: #FFFFFF;
  --bevi-green2: #83BB26;
  --bevi-blue: #1E1C77;

  --bevi-black: #1D1D1B;
  --bevi-65-black: #7C7C7B;
  --bevi-35-black: #BDBCBC;
  --bevi-12-black: #E9E9E9;
}
