.navBar {
  display: flex; /* desktop */
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  background-color: var(--bevi-green);
  width: 70px;
  box-shadow: 5px 0px 20px 5px rgba(0,0,0,0.5);
  margin-right: 30px;
  z-index: 9999;
}

.navBar.active {
  margin-right: 150px;
}

.navBar p {
  margin-left: 10px;
  font-weight: bold;
  color: var(--vt-gray);
}

.navBar .settingsUserInfo {
  margin: 10px 0 10px 10px;
  font-weight: bold;
  color: var(--vt-gray);
}

.navBar a {
  cursor: pointer;
  margin-left: 10px;
}

.navBar a:hover {
  text-decoration: underline;
}

.navBar .icon, .settingsIcon {
  width: 60px;
  height: 60px;
  /* background-image set in js. */
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
}

.navBar .iconComponent {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar .iconComponent svg {
  width: 40px;
  height: 40px;
}

button.navButton {
  background-color: transparent;
  margin: 50px 0;
  width: 60px;
  height: 60px;
  border-radius: 10px;

  /* background-image set in js. */
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
}

button.navButton:hover {
  background-color: var(--bevi-green2);
}

button.navButton.active {
  background-color: var(--bevi-green2);
  margin: 45px 0;
  width: 180px;
  height: 70px;
  border-radius: 20px;
  transform: translate(80px);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
}

button.navButton.active .icon, button.navButton.active .iconComponent {
  margin-left: 15px;
}

.navButtonText {
  display: none;
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: var(--vt-gray);
}

.navButtonText.active {
  display: block;
}

.hoverButtonPopoverWrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.hoverButtonPopoverContent {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  background-color: var(--bevi-green2);
  padding: 16px 16px 16px 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.hoverButtonPopoverWrapper:hover .hoverButtonPopoverContent {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.hoverButtonPopoverWrapper:hover button.navButton {
  visibility: hidden;
}

.hoverButtonPopoverContent .linkButton {
  cursor: pointer;
  margin-left: 10px;
}

.hoverButtonPopoverContent .linkButton:hover {
  text-decoration: underline;
}

@media all and (max-width: 992px) {
  .navBar {
    position: fixed;
    bottom: 0;
    flex-direction: row;
    width: 100%;
    height: 70px;
    padding: 0 5%;
    justify-content: space-between;
  }

  button.navButton.active {
    margin: 0;
    transform: unset;
    flex-direction: column;
    height: 60px;
    width: 60px;
    border-radius: 10px;
  }

  .hoverButtonPopoverWrapper {
    margin-bottom: 0;
  }

  .hoverButtonPopoverWrapper:hover button.navButton {
    visibility: visible;
    background-color: var(--bevi-green2);
    border-radius: 0 0 10px 10px;
  }

  .hoverButtonPopoverContent {
    flex-direction: column;
    top: unset;
    left: unset;
    bottom: 110px;
    right: 0px;
    padding: 16px;
    border-radius: 20px 20px 0 20px;
  }

  .navButtonText.active {
    display: none;
  }

  .settingsIcon {
    display: none;
  }

  button.navButton.active::before, button.navButton.active::after, .hoverButtonPopoverContent::after, .hoverButtonPopoverContent::before {
    content: none;
  }

  button.navButton.active .icon, button.navButton.active .iconComponent {
    margin-left: unset;
  }
}
