.sectionBox > h1 {
  margin: 0 0 20px 0;
  position: relative;
  display: inline-block;
}

.sectionBox > h1.danger:after {
  content: '!';
  font-size: 15px;
  text-align: center;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  top: -5px;
  right: -23px;
  background-color: var(--vt-danger);
}

.sectionBox.marginTop {
  margin: 34px 0 0 0;
}

.sectionBox > div {
  background-color: var(--bevi-12-black);
  box-shadow: var(--basic-shadow);
  padding: 10px;
  border-radius: 15px;
  min-height: 50px;
  overflow-x: auto;
}