@import url('../../theming/colors.css');

/* Common for both StatusTimeline and StatusTimelineLegend */
.statusTimeLine {
  --on-color: var(--bevi-green);
  --on-hilight-color: var(--bevi-green2);

  --off-color: var(--bevi-blue);
  --off-hilight-color: #2f54b8;
}

.StatusTimeline {
  display: flex;
  padding: 5px 15px 5px 0;  /* Right: try to match margin on the plots. */
}

.title {
  width: 50px; /* Try to match the left margin on the plots. */
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline {
  flex-grow: 1;
  height: 25px;
  display: flex;
  border-bottom: 1px solid white;
}

.on {
  background-color: var(--on-color);
}

.off {
  background-color: var(--off-color);
}

.block {
  /* Set relative position to allow overlaying the text with abs positioning. */
  position: relative;
  /* flex-grow: ratio;  <- set from react */ 
}

.text {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.2em;
  pointer-events: none;
}

.subDivisions {
  width: 100%;
  height: 100%;
  display: flex;
}

.on > .subDivisions > div:hover {
  background-color:  var(--on-hilight-color);
}

.off > .subDivisions > div:hover {
  background-color:  var(--off-hilight-color);
}


.on > .subDivisions > div {
  border-left: 1px solid var(--on-hilight-color);
}

.off > .subDivisions > div {
  border-left: 1px solid var(--off-hilight-color);
}

.subDivisions > div.startUnknown {
  border-left: 0;
}

.StatusTimelineLegend {
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
  cursor: default;
}

.StatusTimelineLegend > div::before {
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-left: 10px;
}

.StatusTimelineLegend .legendOn::before {
  background-color: var(--on-color);
}

.StatusTimelineLegend .legendOff::before {
  background-color: var(--off-color);
}
