.Login {
  background-color: var(--bevi-white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: var(--bevi-green);
}

.forwardsArrow {
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
  padding: 20px 0;
  line-height: 0;
  width: 40px;
}

.vtInput {
  background-color: var(--bevi-white);
  color: var(--bevi-black);
  margin: 0;
  padding: 0;
  border: 0;
  height: 80px;
  width: 300px;
  font-size: inherit;
}

input.vtInput {
  border: 1px solid var(--bevi-black);
  border-radius: 20px;
  padding: 35px 20px;
}

input.vtInput:focus {
  background-color: var(--bevi-35-black); /* hmm */
}

button.vtInput {
  border-radius: 20px;
}

button.vtInput > div {
  background-color: var(--bevi-35-black); /* hmm */
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  overflow: clip;
  height: 80px;
  display: flex;
  align-items: center;
}

button.vtInput:hover > div, button.vtInput:focus > div {
  background-color: var(--bevi-12-black); /* hmm */
}

button .rightSlot {
  background-color: var(--vt-green);
  padding: 20px 0;
  width: 80px;
  display: flex;
  justify-content: center;
}
