@import url('../../theming/colors.css');

.RecentAlerts {
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.RecentAlerts.hasActiveAlerts:before  {
  content: '!';
  width: 20px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: var(--vt-danger);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alertRow {
  display: flex;
  align-items: center;
  background-color: var(--bevi-35-black);
  border-radius: 10px;
  height: 60px;
}

.alertRow + .alertRow{
  margin-top: 10px;
}

.status {
  width: 60px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: default;
}

.alertContent {
  flex-grow: 1;
}

.rowButton {
  height: 100%;
  width: 60px;
  border-left: 1px solid white;
}

.rowButton button {
  width: 60px;
  height: 60px;
  background-color: inherit;
  border-radius: 0 10px 10px 0;
}

.rowButton button:hover {
  background-color: var(--bevi-green);
}
