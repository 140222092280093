@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');

.TimerangePicker.content > button {
    width: 100%;
    min-width: 250px;
    max-width: 450px;
}

.absranges label {
    font-weight: bold;
}

.content {
    width: 600px;
    background-color: var(--bevi-35-black);
    border-radius: 10px 0 10px 10px;
    display: flex;
    flex-direction: row;
    box-shadow: var(--basic-shadow);
}

.absranges {
    flex-grow: 1;
    padding: 20px;
    width: 50%;
    border-right: 1px solid var(--vt-more-light-gray);
}

.calendar {
    padding: 1em 0;
}


.relranges input[type=radio] {
    display: none;
}

.relranges ul, .relranges li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.relranges label {
    display: block;
    line-height: 1.7;
    padding: 0 20px;
}

.relranges label:hover {
    background-color: var(--bevi-green);
    color: var(--bevi-white)
}

.relranges label.selected {
    background-color: var(--bevi-blue);
    color: var(--bevi-white)
}

@media all and (max-width: 650px) {
    .content {
        width: 100%;
        flex-direction: column;
    }
    .absranges {
        width: 100%;
        padding-bottom: 0;
    }
    .relranges {
        height: 400px;
    }
}
