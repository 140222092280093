.titleContainer {
  display: flex;
  cursor: pointer;
  margin: 34px 0 10px 0;
  width: min-content;
  align-items: center;
  justify-content: center;
}

.titleContainer .name {
  margin: 0;
  white-space: nowrap;
}

.titleContainer .arrow {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  filter: invert();
}